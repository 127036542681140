import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import WrappingList from "../../components/buy-equipment/wrapping-list"
import TopHeroComponent from "../../components/herocomponents/topherocomponent.js"
import ContactForm from "../../components/bodycomponents/contact-form.js"
import RED_BG from "../../images/background-images/bg-red.svg"
import { colors } from "../../utils/colors.js"
import NewArrivalsComponent from "../../components/bodycomponents/newarrivalscomponent.js"
import NEW_ARRIVALL_BG from "../../images/buy-equipment/SSLLC-Equipment_New-Arrivals_Packaging-Equipment.png"

const PackagingEquipment = () => {
  const data = useStaticQuery(graphql`
    query {
      allCategory(
        sort: { fields: [name], order: ASC }
        filter: { slug: { regex: "/packaging-equipment/.*/" } }
      ) {
        nodes {
          slug
          name
        }
        totalCount
      }
    }
  `)

  const level2Items = []
  const level3Mapping = {}

  data.allCategory.nodes.forEach((item) => {
    const parts = item.slug.split("/").filter(Boolean) // Split slug into segments

    if (parts.length > 2) {
      // It's Level 3
      const parentSlug = `/${parts.slice(0, -1).join("/")}` // Ensure leading slash

      // Map Level 3 to its parent (Level 2)
      if (!level3Mapping[parentSlug]) {
        level3Mapping[parentSlug] = []
      }
      level3Mapping[parentSlug].push(item) // Add Level 3 item under its parent
    } else {
      // It's Level 2
      level2Items.push(item) // Add to Level 2 list
    }
  })

  return (
    <Layout>
      <SEO
        title={`Buy Used Packaging Equipment From Leading Manufacturers`}
        description={`Buy high-quality used packaging equipment to support your business, whether you're in the pharmaceutical, chemical, or cosmetics industry.`}
      />
      <TopHeroComponent
        backgroundImage={RED_BG}
        backgroundColor={colors.scarletRed}
        fontShadowColor={colors.red}
        shadowColor="shadow-red-bottom"
        pageTitle="Packaging Equipment"
        title="Your Source for Used Packaging Equipment"
        body={
          <p className="pt-4">
            Surplus Solutions offers a wide range of new and used industrial
            packaging equipment, including blister packaging machines,
            conveyors, and labelers from leading brands to meet your needs.
          </p>
        }
      />
      <div className="shadow-custom-out">
        <WrappingList
          title="Shop All Packaging Equipment"
          data={level2Items}
          slugPrefix={"/catalog"}
          level3Mapping={level3Mapping}
        />
      </div>

      <NewArrivalsComponent
        backgroundImage={NEW_ARRIVALL_BG}
        title={
          <>
            New Arrivals
            {/* <br />
            Packaging Equipment */}
          </>
        }
        body={
          <p>
            Our inventory is frequently updated and tends to sell out quickly.
            Discover the latest lab, processing, and packaging equipment
            arrivals and find the best deals before they run out. The best
            in-demand items often find their buyers almost as fast as they
            appear in our inventory. Be sure to act now and secure the equipment
            you need at excellent prices!
          </p>
        }
        buttonTitle="See New Arrivals >"
        buttonColor={colors.turquoise}
        hoverColor={colors.aqua}
      />
      <ContactForm
        title="Never miss a great deal!"
        body={
          <p>
            Receive weekly emails with our special offers, new arrivals,
            auctions, case studies and more!
          </p>
        }
        fontShadowColor={colors.red}
        backgroundColor={colors.containerGray}
        shadow="shadow-red-bottom"
        formType="Marketing Sign Up"
      />
    </Layout>
  )
}

export default PackagingEquipment
